<template>
  <div class="container">
    <img src="../../assets/sunrise.jpg" />
    <div class="text">
      <div class="title1">
        购买须知
      </div>
      <div class="title2">
        使用规则
      </div>

      <div class="desc">
        购买本权产品后,可在【日上中免|旅购】小程序或日上会员APP下单购买商品享“订单优惠7%权益。
      </div>

      <div class="desc">
        <div>【购买】</div>
        <div>1.活动期限内同一用户(同一用户名、手机号、设备号均视为同一用户)最多可购买10张。</div>
        <div>2.每张折扣券仅限使用一次,每单金额1500元起,最多可购买20件商品,单件商品最多5件(热门单品限购规则以实际显示为准),折扣券不抵扣订单金额。</div>
      </div>

      <div class="desc">
        <div>【绑定领取】</div>
        <div>1.点击右下角我的一礼品卡一去使用；</div>
        <div>2.跳转至在机场PLUS小程序内（需使用购买礼品卡时授权的手机号进行授权）一点击右下角我的,在我的订单右侧点击“出行|服务”进入订单列表,点击相应权益卡订单，在订单详情中操作绑定账户；</div>
        <div>3.绑定成功后,可在我的钱包一权益券中领取使用；</div>
        <div>4.权益卡自购买之日起30天内有效,请及时操作绑定领</div>
      </div>

      <div class="desc">
        <div>【使用】</div>
        <div>1.折扣券:至在机场PLUS小程序内点击右下角我的一权益券一去使用,详细介绍可点击上方“折扣券”查看；</div>
        <div>2.券自绑定取后30天内有效，过期则无法使用；</div>
        <div>3.实际库存信息以【日上中免|旅购】小程提交订单之后【仓库通知】为准；</div>
        <div>4.关于【日上中免|旅购】小程序或订单是否取消成功，请30分钟后查看券是否返还，请注意下单时间避免错过券的使用有效期，逾期自动作废，不予退换/退款；</div>
        <div>由于商品特殊性，无法取消订单带来的不便也请谅解。</div>
        <div>（具体退款和返还券的规则以旅购平台为准）</div>
      </div>

      <div class="title2">
        退款规则
      </div>

      <div class="desc">
        <div>【退款】</div>
        <div>折扣卡一经售出，无法退款，请谨慎操作。</div>
      </div>
    </div>
    <div class="holder"></div>
    <div class="footer">
      <div class="price">￥168</div>
      <div class="blank"></div>
      <div class="btn">
        <el-button type="default" @click="my">我的</el-button>
      </div>
      <div class="btn">
        <el-button type="primary" :loading="loading" @click="createPrePay">购买</el-button>
      </div>
    </div>
  </div>
  <el-dialog v-model="showDialog" :show-close="true" :close-on-click-modal="false" :close-on-press-escape="false"
    :append-to-body="true" center width="400px" @close="stopTimer">
    <template #title>
      <span>请使用微信扫码支付</span>
    </template>
    <div id="qrcode"></div>
  </el-dialog>

  <el-dialog v-model="showDialog2" :show-close="true" :close-on-click-modal="false" :close-on-press-escape="false"
    :append-to-body="true" center width="400px">
    <template #title>
      <span>购买成功</span>
    </template>
    <div id="qrcode">
      券码: {{vipCode}}
    </div>
  </el-dialog>
</template>

<script>
  import {
    createPrePay,
    getPayParam,
    getVipCode
  } from "@/api/sunrise"
  import {
    qrcanvas
  } from 'qrcanvas'

  export default {
    data() {
      return {
        recommend: null,
        membercode: '',
        price: 168,
        prepay_id: null,
        loading: false,
        showDialog: false,
        showDialog2: false,
        timer: null,
        vipCode: ''
      }
    },
    mounted() {
      this.recommend = sessionStorage.getItem(process.env.VUE_APP_STORE_RECOMMEND_KEY)
      this.membercode = sessionStorage.getItem(process.env.VUE_APP_STORE_MEMBERCODE_KEY)

      if (!this.membercode) {
        this.$router.push('/sunrise');
      }
    },
    methods: {
      my() {
        this.$router.push('/sunrise/my');
      },
      createPrePay() {
        this.loading = true

        createPrePay('web_' + this.membercode, 1, this.recommend)
          .then(res => {
            if (res.success) {
              this.prepay_id = res.prepay_id
              this.getPayParam();
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      },
      getPayParam() {
        getPayParam(this.prepay_id, 'NATIVE')
          .then(res => {
            if (res.success) {
              this.loading = false
              this.showDialog = true
              this.$nextTick(() => {
                var qr = qrcanvas({
                  data: res.code_url,
                  size: 300,
                  colorDark: 'red'
                })
                document.getElementById("qrcode").innerHTML = ""
                document.getElementById("qrcode").appendChild(qr)
                this.startTimer()
              })
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      },
      startTimer() {
        this.timer = setTimeout(this.dotimer, 3000);
      },
      stopTimer() {
        clearTimeout(this.timer);
      },
      dotimer() {
        getVipCode(this.prepay_id)
          .then(res => {
            if (res.success) {
              this.stopTimer()
              this.showDialog = false
              this.vipCode = res.code
              this.$nextTick(() => {
                this.showDialog2 = true
              })
            } else {
              this.startTimer()
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    width: 666px;
    max-width: calc(100vw - 20px);
    margin: 0 auto;

    img {
      max-width: 100%;
      margin-top: 10px;
    }

    .text {
      width: 90%;
      margin: 0 auto;

      .title1 {
        margin-top: 50px;
        font-size: 1.9rem;
      }

      .title2 {
        margin-top: 30px;
        font-size: 1.6rem;
      }

      .desc {
        margin-top: 30px;
        font-size: 1.2rem;
        color: #999;
      }
    }

    .holder {
      height: 80px;
    }

    .footer {
      position: fixed;
      height: 50px;
      bottom: 0;
      background-color: #fff;
      width: 666px;
      max-width: 100%;
      line-height: 50px;
      border-top: solid 1px #ccc;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .price {
        color: red;
        margin-left: 20px;
      }

      .blank {
        flex-grow: 1;
      }

      .btn {
        margin-right: 20px;
      }
    }
  }

  #qrcode {
    width: 300px;
    margin: 20px auto;
    text-align: center;
  }
</style>
