import request from '@/utils/request'

export function phoneVerify(phone) {
  return request({
    url: '/Account/phoneVerify',
    method: 'post',
    params: {
      phone
    }
  })
}

export function webbind(phone, code) {
  return request({
    url: '/Account/webbind',
    method: 'post',
    params: {
      phone,
      code
    }
  })
}

export function createPrePay(openid, type, recommend_id) {
  return request({
    url: '/Pay/createPrePay',
    method: 'post',
    params: {
      openid,
      type,
      recommend_id
    }
  })
}

export function getPayParam(prepay_id, trade_type) {
  return request({
    url: '/Pay/getPayParam',
    method: 'post',
    params: {
      prepay_id,
      trade_type
    }
  })
}

export function getVipCode(prepay_id) {
  return request({
    url: '/Pay/GetVipCode',
    method: 'post',
    params: {
      prepay_id
    }
  })
}

export function getMyVipCodeList(openid) {
  return request({
    url: '/Mine/getMyVipCodeList',
    method: 'post',
    params: {
      openid
    }
  })
}
